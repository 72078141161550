import React, {useCallback, useEffect, useState} from 'react';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../../errors';
import BackButton from '../../Components/BackButton';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import Resource from 'rev.sdk.js/Generic/Resource';
import WorksDetail from './WorksDetail';
const qs = require('query-string');
import {timestampFormat} from '../../Utils/DateUtil';

export default function WorksPage(props) {
  const [loading, setLoading] = useOutlet('loading');
  const [profile] = useOutlet('user');
  const [token] = useOutlet('jwtToken');

  const fetchRecords = useCallback(async ({sort, paging, keyword}) => {
    let query = keyword
      ? {
          title: {
            $regex: `${keyword}`,
          },
        }
      : {};

    return JStorage.fetchDocuments(
      'works',
      query,
      sort ? [sort] : [],
      paging,
      null,
      {content: 0, variant: 0},
    );
  }, []);

  const fetchRecordById = useCallback(async (id) => {
    return JStorage.fetchOneDocument('works', {id}, null);
  }, []);

  const deleteRecordById = useCallback(
    async (id) => {
      return JStorage.deleteDocument('works', {id}, {token});
    },
    [token],
  );

  // return (
  //   <Wrapper>
  //     <WorksDetail />
  //   </Wrapper>
  // )

  return (
    <Wrapper>
      <Resource
        querySpec={{
          // pageSizeOptions: [10],
          // sortOptions: [
          //   { name: '日期近到遠', value: 'created' },
          //   { name: '日期遠到近', value: '-created' },
          // ],
          pageSizeOptions: [10],
          sortOptions: [
            {
              name: '更新時間由新到舊',
              value: '-updated',
            },
            {
              name: '建立時間由新到舊',
              value: '-created',
            },
          ],
          canSearch: true,
          searchPlaceHolder: '標題',
        }}
        location={props.location}
        spec={{
          path: '/works',
          name: '作品集',
          primaryKey: 'id',
          searchField: 'title',
          actions: {
            setLoading: setLoading,
            fetchRecords,
            fetchRecordById,
            deleteRecordById,
          },
          columns: [
            {
              title: '標題',
              key: 'title',
              dataIndex: 'title',
            },
            {
              title: '分類',
              key: 'label',
              dataIndex: 'label',
            },
            {
              title: '建立日期',
              key: 'created',
              dataIndex: 'created',
              render: (_, record) => (
                <div>{timestampFormat(record.created)}</div>
              ),
            },
          ],
        }}
        // renderCreateButton={renderCreateButton}
        // renderDeleteButton={renderDeleteButton}
        // renderDetailButton={renderDetailButton}
        // renderCustomSection={(props) => <div></div>}
        renderDetail={(props) => {
          return <WorksDetail instance={props.instance} />;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f5f5f5;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
