import React, {useCallback, useMemo, useState, useRef} from 'react';
import Form from 'rev.sdk.js/Generic/Form';
import {useOutlet} from 'reconnect.js';
import Button from '../../Widgets/Button';
import RichTextEditor, {Provider} from 'rev.sdk.js/Components/RichTextEditor';
import RevFilesUploader from 'rev.sdk.js/Generic/CustomFields/RevFilesUploader';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {message} from 'antd';

function RichTextField(props) {
  const {name, formData, onChange, schema} = props;

  return (
    <div>
      <p>{schema.title}</p>
      <div
        style={{backgroundColor: '#fff', minHeight: 200, padding: '4px 11px'}}>
        <Provider
          content={formData}
          onChange={(value) => {
            onChange(value);
          }}>
          <RichTextEditor />
        </Provider>
      </div>
    </div>
  );
}

const schema = {
  title: '',
  type: 'object',
  required: ['title', 'label'],
  properties: {
    title: {
      title: '標題',
      type: 'string',
    },
    label: {
      title: '分類',
      minLength: 1,
      type: 'array',
      items: {
        type: 'string',
      },
    },
    // date: {
    //   type: 'string',
    //   pattern: '^[0-9]{6}$',
    // },
    // images: {
    //   type: 'array',
    //   minLength: 1,
    //   removable: true,
    //   items: {
    //     type: 'string',
    //   },
    //   default: [],
    // },
    files: {
      type: 'array',
      minLength: 1,
      removable: true,
      items: {
        type: 'object',
      },
      default: [],
    },
    variant: {
      title: '作品規格',
      type: 'array',
      items: {
        type: 'object',
      },
      default: [
        {
          type: 'p',
          children: [{text: ''}],
        },
      ],
    },
    content: {
      title: '作品內容',
      type: 'array',
      items: {
        type: 'object',
      },
      default: [
        {
          type: 'p',
          children: [{text: ''}],
        },
      ],
    },
  },
};

const uiSchema = {
  variant: {
    'ui:field': RichTextField,
  },
  content: {
    'ui:field': RichTextField,
  },
  files: {
    'ui:field': (props) => {
      const {name} = props;
      const {token, fileRefs} = props.formContext;

      fileRefs.current[name] = {};
      return (
        <RevFilesUploader
          {...props}
          refer={fileRefs.current[name]}
          token={token}
        />
      );
    },
  },
};

const errorSchema = {
  title: {
    required: '標題為必填',
    // pattern: '6 digits',
  },
  label: {
    required: '分類為必填',
  },
  files: {
    required: '圖片為必填',
    minLength: '至少選一張',
  },
};

const UISTATE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export default function WorksDetail(props) {
  const {instance} = props;
  const [uiState] = useState(!!instance ? UISTATE.EDIT : UISTATE.CREATE);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [token] = useOutlet('jwtToken');
  const [record, setRecord] = useState(instance);
  const fileRefs = useRef({});

  const submit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        if (uiState === UISTATE.CREATE) {
          await JStorage.createDocument('works', data, {token});
        } else {
          await JStorage.updateDocument('works', {id: instance.id}, data, {
            token,
          });
        }
        message.success('儲存成功');
        actions.navigate('/works/');
      } catch (err) {
        message.error('儲存錯誤');
      }
      setLoading(false);
    },
    [token],
  );

  return (
    <div>
      <h2>{uiState === UISTATE.EDIT ? '編輯 # ' + instance.id : '新增'}</h2>
      <Form
        instance={record}
        schema={schema}
        uiSchema={uiSchema}
        errorSchema={errorSchema}
        onSubmit={async (formData, extValues = {}) => {
          let nextFormData = {...formData};
          if (Object.keys(fileRefs.current).length) {
            for (let key of Object.keys(fileRefs.current)) {
              nextFormData[key] = await fileRefs.current[key].onUpload();
            }
          }

          submit(nextFormData);
        }}
        renderCustomSubmitButton={(
          {values, instance, setValues, extValues, setExtValues},
          submitBtnRef,
        ) => (
          <Button onClick={() => submitBtnRef.current?.click()}>送出</Button>
        )}
        renderCustomSection={null}
        formContext={{token, fileRefs}}
      />
    </div>
  );
}
